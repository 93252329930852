import { Environment } from './environment.model';

export const environment: Environment = {
  apiUrl: 'https://enerix-webapp-staging.lab.inspeerity.com/api/',
  appleLogin:
    'https://enerix-staging.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=https://enerix-webapp-staging.lab.inspeerity.com/login&response_type=CODE&client_id=7fnst3qlp8stad399pe5i8iv1d&scope=aws.cognito.signin.user.admin%20openid',
  googleLogin:
    'https://enerix-staging.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://enerix-webapp-staging.lab.inspeerity.com/login&response_type=CODE&client_id=7fnst3qlp8stad399pe5i8iv1d&scope=aws.cognito.signin.user.admin%20openid',
  hubspot: {
    portalId: '144047131',
  },
};
