import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AuthDataAccessTokenInterceptor } from '@enx/auth/data-access';
import { SharedFeatureErrorInterceptor, SharedFeatureLoaderInterceptor } from '@enx/shared/feature/interceptors';
import { SharedFeatureSnackbarService } from '@enx/shared/feature/services';
import { TranslateConfigUtil } from '@enx/shared/util/utils';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { APP_ROUTES } from './app.routes';
import { HammerModule } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(TranslateModule.forRoot(TranslateConfigUtil.createTranslateConfig())),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(HammerModule),
    { provide: HTTP_INTERCEPTORS, useClass: AuthDataAccessTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SharedFeatureErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SharedFeatureLoaderInterceptor, multi: true },
    SharedFeatureSnackbarService,
  ],
};
