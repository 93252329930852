import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SharedFeatureSnackbarService } from '@enx/shared/feature/services';
import { CustomError } from '@enx/shared/util/models';
import { catchError, filter, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureErrorInterceptor implements HttpInterceptor {
  private readonly snackbarService = inject(SharedFeatureSnackbarService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      filter((response: HttpEvent<unknown>): response is HttpResponse<unknown> => !!response),
      catchError((errorResponse: CustomError) => {
        const errorCodes: string[] = errorResponse.error?.errors?.map((error) => error.code) || [];

        if (errorResponse.error?.message === 'User not registered.') {
          const message = `Benutzer nicht vorhanden. <a href="https://www.enerix.de/sofort-selbst-planen/">Klicken Sie hier, um sich zu registrieren.</a>`;
          this.snackbarService.openHtmlErrorSnackbar(message);
        } else {
          const generalErrorCode: string[] = ['something_went_wrong'];
          const incorrectCredentialsCode: string[] = ['incorrect_username_password'];

          const errorMessage =
            errorResponse.error?.message === 'Incorrect username or password.'
              ? incorrectCredentialsCode
              : errorCodes.length
              ? errorCodes
              : generalErrorCode;

          this.snackbarService.openErrorSnackbar(errorMessage);
        }

        return throwError(() => errorResponse);
      }),
    );
  }
}
